
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Table, TableRowsSelect } from "@/utils/decorator";
import { emptyToLine, splitThousands,booleanFormat,enterWeComFormat,downloadAppFormat,followOfficialAccountFormat } from "@/utils/common";
import DataImport from "@/components/scope/data-import.vue";
import SwitchStatus from "@/components/scope/switch-status.vue";
import t from "@common/src/i18n/t";
import dayjs from "dayjs";

const customer = namespace("customer");

@Component({
  components: { DataImport, SwitchStatus }
})
@Table("loadListData")
@TableRowsSelect({
  warningMsg: t("v210906.please-select-at-least-one-customer"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})
@Table.DataImport({
  templateUrl: "https://speedbiz-public.oss-cn-beijing.aliyuncs.com/static/template/speedbiz/客户车辆导入.xlsx",
  actionUrl: "/filecloud/importCustomerVehicles"
})
export default class Customer extends Vue {
  dayjs = dayjs;
  @customer.Action getNewCustomerList;
  splitThousands = splitThousands;
  @customer.Action updateCustomerStatus;
  @customer.Action batchUpdateVehicleStatus;
  emptyToLine = emptyToLine;
  booleanFormat = booleanFormat;
  enterWeComFormat = enterWeComFormat;
  downloadAppFormat = downloadAppFormat;
  followOfficialAccountFormat = followOfficialAccountFormat;
  searchForm = {
    customerName: "",
    mobile: "",
    businessSource: ""
  };
  showImport: boolean = false;
  customerList: any[] = [];
  searchList: any[] = [
    {
      label: "用户名称",
      type: "input",
      value: "",
      prop: "customerName"
    },
    {
      label: t("base.phone"),
      type: "input",
      value: "",
      prop: "mobile"
    },
    {
      label: '业务来源',
      type: "input",
      value: "",
      prop: "businessSource"
    },
    {
      label: "经验值等级",
      type: "select",
      selectList: [
        {
          label: "1",
          value: "1"
        },
        {
          label: "2",
          value: "2"
        },
        {
          label: "3",
          value: "3"
        },
        {
          label: "4",
          value: "4"
        }
      ],
      value: "",
      prop: "level"
    }
  ];

  created() {
    this.init();
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }

  @Table.LoadDataMethod()
  loadListData() {
    return this.getNewCustomerList({
      orderItems: this.mix_sortParams,
      ...this.searchForm,
      pageSize: this.mix_pageSize,
      pageNum: this.mix_pageNum
    }).then(data => {
      this.customerList = data.data.list;
      return data;
    });
  }
  handleEdit(row) {
    this.$router.push(`/setting/customer-edit/${row.customerCode}?vehicleCode=${row.vehicleCode}`);
  }
  handleView(row) {
    this.$router.push(`/setting/customer-view/${row.customerCode}?vehicleCode=${row.vehicleCode}`);
  }
  handleShowImport() {
    this.showImport = true;
  }
  init() {
    this.loadListData();
  }
}
